import React, { FC, useMemo } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  IconButton,
  InputAdornment,
  useTheme
} from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  Checkbox,
  Input,
  ParagraphBold,
  ParagraphSmall,
  Select
} from "@likemagic-tech/sv-magic-library";
import {
  DueDurationType,
  Priority,
  ServiceConfig,
  ServiceConfigType,
  TaskConfig,
  TaskGranularity
} from "../../../../graphql-tenantconfig/generated/graphql";
import { FormikErrors, useFormikContext } from "formik";
import { useGetActors } from "../../../../features/tasks/use-get-actors";
import ClearIcon from "@mui/icons-material/Clear";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface TaskFieldsProps {}

export const TaskFields: FC<TaskFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange, setFieldValue } = useFormikContext<ServiceConfig>();

  const { actorGroupOptions } = useGetActors();

  const priorityOptions = Object.values(Priority).map((type) => ({
    label: t("labels__settings_service_config_task_priority_" + type),
    value: type
  }));

  const taskGranularityOptions = Object.values(TaskGranularity).map((type) => ({
    label: t("labels__settings_service_config_task_granularity_" + type),
    value: type
  }));

  const dueDurationTypeOptions = Object.values(DueDurationType).map((type) => ({
    label: t("labels__settings_service_config_task_due_duration_type_" + type),
    value: type
  }));

  const actorGroupSelectOptions = useMemo(() => {
    return actorGroupOptions.map((o) => ({ label: o.label, value: o.label }));
  }, [actorGroupOptions]);

  const taskErrors = useMemo(() => {
    return errors.task as FormikErrors<TaskConfig>;
  }, [errors]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_task_housekeeping_main_task")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_housekeeping_main_task_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="task.housekeepingMainTask"
                  name="task.housekeepingMainTask"
                  checked={!!values.task?.housekeepingMainTask}
                  value={true}
                  disabled={!canEditServiceConfig || ServiceConfigType.FreeCleaning === values.type}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_task_housekeeping_main_task_checkbox")}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_task_create_multiple_tasks")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_create_multiple_tasks_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="task.createMultipleTasks"
                  name="task.createMultipleTasks"
                  checked={!!values.task?.createMultipleTasks}
                  value={true}
                  disabled={!canEditServiceConfig || ServiceConfigType.FreeCleaning === values.type}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_task_create_multiple_tasks_checkbox")}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <ParagraphBold>{t("labels__service_config_form_task_assignee_group")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_assignee_group_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="task.assigneeGroup"
              name="task.assigneeGroup"
              variant="outlined"
              value={values.task?.assigneeGroup ?? ""}
              options={actorGroupSelectOptions}
              onChange={handleChange}
              error={!!taskErrors?.assigneeGroup}
              disabled={!canEditServiceConfig}
            />
            <FormHelperText error required>
              {taskErrors?.assigneeGroup?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_task_due_duration_type")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_due_duration_type_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="task.dueDurationType"
              name="task.dueDurationType"
              variant="outlined"
              value={values.task?.dueDurationType ?? ""}
              options={dueDurationTypeOptions}
              onChange={handleChange}
              error={!!taskErrors?.dueDurationType}
              disabled={!canEditServiceConfig}
              endAdornment={
                canEditServiceConfig &&
                !!values.task?.dueDurationType && (
                  <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("task.dueDurationType", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error required>
              {taskErrors?.dueDurationType?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_task_due_duration")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_due_duration_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Input
              id="task.dueDuration"
              name="task.dueDuration"
              variant="outlined"
              value={values.task?.dueDuration ?? ""}
              onChange={handleChange}
              type={"text"}
              error={!!taskErrors?.dueDuration}
              disabled={!canEditServiceConfig}
              endAdornment={
                canEditServiceConfig &&
                !!values.task?.dueDuration && (
                  <InputAdornment sx={{ position: "absolute", right: 7 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("task.dueDuration", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
              sx={{
                ".MuiInputBase-input": {
                  paddingY: theme.spacing(2)
                }
              }}
            />
            <FormHelperText error required>
              {taskErrors?.dueDuration?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_task_granularity")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_granularity_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="task.granularity"
              name="task.granularity"
              variant="outlined"
              value={values.task?.granularity ?? ""}
              options={taskGranularityOptions}
              onChange={handleChange}
              error={!!taskErrors?.granularity}
              disabled={!canEditServiceConfig || ServiceConfigType.FreeCleaning === values.type}
            />
            <FormHelperText error required>
              {taskErrors?.granularity?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_task_priority")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_task_due_priority_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="task.priority"
              name="task.priority"
              variant="outlined"
              value={values.task?.priority ?? ""}
              options={priorityOptions}
              onChange={handleChange}
              error={!!taskErrors?.priority}
              disabled={!canEditServiceConfig || ServiceConfigType.FreeCleaning === values.type}
              endAdornment={
                canEditServiceConfig &&
                !!values.task?.priority && (
                  <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("task.priority", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error required>
              {taskErrors?.priority?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
      </Grid2>
    </>
  );
};
