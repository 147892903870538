import React, { FC, useMemo } from "react";
import { Grid2, useTheme } from "@mui/material";
import { UserAvatar } from "../../components/user-avatar";
import { Chip, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import { ConversationDetails } from "../domain/conversation";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { capitalizeFirstLetter } from "../utils/utils";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { grey } from "@mui/material/colors";
import { EmailOutlined, WhatsApp } from "@mui/icons-material";

interface ChatPreviewHeaderProps {
  conversation: ConversationDetails;
}

const ellipsisProps = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap"
};

export const ChatPreviewHeader: FC<ChatPreviewHeaderProps> = ({ conversation }) => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const userInitials = useMemo(() => {
    if (conversation.guestIdentity?.firstName && conversation.guestIdentity?.lastName) {
      return `${conversation.guestIdentity?.firstName?.charAt(
        0
      )}${conversation.guestIdentity?.lastName?.charAt(0)}`;
    }
    return "";
  }, [conversation.guestIdentity]);
  const { t } = useTranslationWrapper();

  return (
    <Grid2 spacing={1.5} container direction="column">
      <Grid2
        container
        direction="row"
        alignItems="center"
        spacing={1}
        flexWrap="nowrap"
        sx={{ width: "100%" }}
      >
        <UserAvatar
          lettersToDisplay={userInitials}
          sx={{
            border: 1,
            color: palette.common.black,
            height: isMobile ? 30 : 40,
            width: isMobile ? 30 : 40,
            p: 2
          }}
        />
        <Heading2
          sx={{
            ...ellipsisProps,
            pr: isMobile ? 5 : 0
          }}
        >
          {conversation.guest.displayName}
        </Heading2>
      </Grid2>
      <Grid2 container spacing={1}>
        {conversation.guestIdentity?.gender && (
          <Chip
            size="medium"
            color="default"
            variant="outlined"
            label={t(
              "labels__gender_" +
                capitalizeFirstLetter(conversation.guestIdentity?.gender?.toLowerCase() ?? "")
            )}
          />
        )}
        <Chip
          color="info"
          size="medium"
          label={t(
            conversation.guestIdentity?.reservations &&
              conversation.guestIdentity?.reservations.length > 1
              ? "labels__recurring"
              : "labels__new"
          )}
        />
      </Grid2>

      <Grid2 container sx={{ justifyContent: "flex-start", maxWidth: "100%" }}>
        {conversation.guestIdentity?.phone && (
          <Grid2
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ display: "flex", gap: 1, color: grey["700"] }}
          >
            <WhatsApp />
            <Paragraph color="inherit" sx={ellipsisProps}>
              {conversation.guestIdentity?.phone}
            </Paragraph>
          </Grid2>
        )}

        {conversation.guestIdentity?.email && (
          <Grid2
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ display: "flex", gap: 1, color: grey["700"] }}
          >
            <EmailOutlined />
            <Paragraph color="inherit" sx={ellipsisProps}>
              {conversation.guestIdentity?.email}
            </Paragraph>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};
