import { FC, useState } from "react";
import { DefaultServiceConfig } from "../../../graphql-tenantconfig/generated/graphql";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { WarningAmber } from "@mui/icons-material";
import { price, sortedUnitGroups, vat } from "./service-config-utils";

interface DefaultServicesTableRowProps {
  data: DefaultServiceConfig;
  onSelect?: (obj: DefaultServiceConfig) => void;
}

const DefaultServicesTableRow: FC<DefaultServicesTableRowProps> = ({ data, onSelect }) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow hover onClick={() => onSelect && onSelect(data)}>
        <TableCell sx={{ paddingLeft: spacing(1), borderBottomWidth: open ? 0 : 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
            sx={{ marginRight: spacing(1) }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {data.conflicts && data.conflicts.length > 0 && (
            <Tooltip title={data.conflicts.map((c) => c.type).join("\n")}>
              <WarningAmber color="error" sx={{ mb: -0.8, mr: 1 }} />
            </Tooltip>
          )}
          <b>{data.service.details?.displayName || data.service.serviceId}</b>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.pmsId || data.service.serviceId}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.price && price(data.service.details?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.price && vat(data.service.details?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.chargeMode && (
            <>
              {t("labels__settings_service_config_charge_mode_" + data.service.details?.chargeMode)}
            </>
          )}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.chargeUnit && (
            <>
              {t("labels__settings_service_config_charge_unit_" + data.service.details?.chargeUnit)}
            </>
          )}
        </TableCell>
      </TableRow>
      {data.unitGroups &&
        sortedUnitGroups(data.unitGroups).map((unitGroup, index) => (
          <TableRow
            key={`default-service-unit-group-${data.service.serviceId}-${unitGroup.unitGroupId}`}
          >
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.unitGroups!.length - 1 ? 1 : 0
              }}
              sx={{ paddingLeft: spacing(6.5) }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: spacing(index === data.unitGroups!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  {data.conflicts &&
                    data.conflicts.filter((c) => c.relatedUnitGroupId === unitGroup.unitGroupId)
                      .length > 0 && (
                      <Tooltip
                        title={data.conflicts
                          .filter((c) => c.relatedUnitGroupId === unitGroup.unitGroupId)
                          .map((c) => c.type)
                          .join("\n")}
                      >
                        <WarningAmber color="error" sx={{ mb: -0.8, mr: 1 }} />
                      </Tooltip>
                    )}
                  {unitGroup.details?.name || unitGroup.unitGroupId}
                </Box>
              </Collapse>
            </TableCell>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.unitGroups!.length - 1 ? 1 : 0
              }}
              colSpan={5}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: spacing(index === data.unitGroups!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  {unitGroup.unitGroupId}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default DefaultServicesTableRow;
