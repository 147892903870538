import * as Types from "../generated/graphql";

import { ServiceIdentifierFragmentDoc } from "./ServiceIdentifier.generated";
import { ServiceContractFragmentDoc } from "./ServiceContract.generated";
import { ServiceConfigConflictFragmentDoc } from "./ServiceConfigConflict.generated";
export type ServiceDependencyGroupFragment = {
  __typename?: "ServiceDependencyGroup";
  key: string;
  services?: Array<{
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  }> | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export const ServiceDependencyGroupFragmentDoc = `
    fragment ServiceDependencyGroup on ServiceDependencyGroup {
  key
  services {
    ...ServiceIdentifier
  }
  conflicts {
    ...ServiceConfigConflict
  }
}
    `;
