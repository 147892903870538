import {
  AdditionalCleaningServiceConfig,
  AddOnServiceConfig,
  AddOnType,
  BookableDay,
  ConflictType,
  DefaultDatePickerSelection,
  DefaultServiceConfig,
  DefaultServiceConfigInput,
  EarlyCheckinServiceConfig,
  FreeCleaningServiceConfig,
  LateCheckoutServiceConfig,
  Price,
  ReservationStatus,
  ServiceConfig,
  ServiceConfigConflict,
  ServiceConfigGranularity,
  ServiceConfigInput,
  ServiceConfigurationInputContainer,
  ServiceDependencyGroup,
  ServiceDependencyGroupInput,
  ServiceIdentifier,
  TaskConfig,
  TaskGranularity,
  UnitGroupIdentifier,
  Visibility
} from "../../../graphql-tenantconfig/generated/graphql";
import { Order } from "../../../types/pageable";
import { ServiceConfigurationSandbox } from "../../../slices/service-configuration-sandbox";
import { ServiceConfigType } from "../../../graphql/generated/graphql";

export const vat = (price?: Price): string => {
  if (price && price.netPrice && price.grossPrice) {
    try {
      return new Intl.NumberFormat(navigator.language, {
        style: "percent"
      }).format((price.grossPrice - price.netPrice) / price.netPrice);
    } catch (e) {
      return "";
    }
  }
  return "";
};

export const price = (price?: Price): string => {
  if (price && price.grossPrice && price.currency) {
    try {
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: price.currency
      }).format(price.grossPrice);
    } catch (e) {
      return "";
    }
  }
  return "";
};

export const sortedUnitGroups = (unitGroups: UnitGroupIdentifier[]) => {
  return unitGroups
    ? [...unitGroups].sort((a, b) =>
        (a.details?.name || a.unitGroupId).localeCompare(b.details?.name || b.unitGroupId)
      )
    : [];
};

export const sortedServices = (services: ServiceIdentifier[]) => {
  return services
    ? [...services].sort((a, b) =>
        (a.details?.name || a.serviceId).localeCompare(b.details?.name || b.serviceId)
      )
    : [];
};

const BOOKABLE_DAYS_ORDER = [
  BookableDay.Monday,
  BookableDay.Tuesday,
  BookableDay.Wednesday,
  BookableDay.Thursday,
  BookableDay.Friday,
  BookableDay.Saturday,
  BookableDay.Sunday
];
export const sortedBookableDays = (bookableDays: BookableDay[]) => {
  return bookableDays
    ? [...bookableDays].sort(
        (a, b) => BOOKABLE_DAYS_ORDER.indexOf(a) - BOOKABLE_DAYS_ORDER.indexOf(b)
      )
    : [];
};

const RESERVATION_STATUS_ORDER = [
  ReservationStatus.Confirmed,
  ReservationStatus.InHouse,
  ReservationStatus.CheckedOut
];
export const sortedReservationSatuses = (reservationStatuses: ReservationStatus[]) => {
  return reservationStatuses
    ? [...reservationStatuses].sort(
        (a, b) => RESERVATION_STATUS_ORDER.indexOf(a) - RESERVATION_STATUS_ORDER.indexOf(b)
      )
    : [];
};

const VISIBILITY_ORDER = [Visibility.GuestFlow, Visibility.MyStay, Visibility.Backoffice];
export const sortedVisibilities = (visibilities: Visibility[]) => {
  return visibilities
    ? [...visibilities].sort((a, b) => VISIBILITY_ORDER.indexOf(a) - VISIBILITY_ORDER.indexOf(b))
    : [];
};

export const stringIgnoreCaseComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  let aValue = (a[orderBy] as string) || "";
  let bValue = (b[orderBy] as string) || "";
  return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
};

export const serviceDisplayNameComparator = (
  a: ServiceConfig | DefaultServiceConfig,
  b: ServiceConfig | DefaultServiceConfig
) => {
  let aDisplayName = a?.service?.details?.displayName || a?.service.serviceId || "";
  let bDisplayName = b?.service?.details?.displayName || b?.service.serviceId || "";
  return aDisplayName.toLowerCase().localeCompare(bDisplayName.toLowerCase());
};

export const servicePmsIdComparator = (
  a: ServiceConfig | DefaultServiceConfig,
  b: ServiceConfig | DefaultServiceConfig
) => {
  let aServiceId = a?.service.serviceId || "";
  let bServiceId = b?.service.serviceId || "";
  return aServiceId.toLowerCase().localeCompare(bServiceId.toLowerCase());
};

export const serviceConfigTypeComparator = (
  a: ServiceConfig,
  b: ServiceConfig,
  t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
) => {
  let aType = a?.type ? t("labels__settings_service_config_type_" + a.type) : "";
  let bType = b?.type ? t("labels__settings_service_config_type_" + b.type) : "";
  return aType.toLowerCase().localeCompare(bType.toLowerCase());
};

export const getComparator = <T,>(
  order: Order,
  orderBy: string,
  t: (key: string, additionalInterpolationMap?: Record<string, string>) => string
): ((a: T, b: T) => number) => {
  if ("service.details.displayName" === orderBy) {
    return order === "desc"
      ? (a, b) =>
          -serviceDisplayNameComparator(
            a as ServiceConfig | DefaultServiceConfig,
            b as ServiceConfig | DefaultServiceConfig
          )
      : (a, b) =>
          serviceDisplayNameComparator(
            a as ServiceConfig | DefaultServiceConfig,
            b as ServiceConfig | DefaultServiceConfig
          );
  } else if ("service.serviceId" === orderBy) {
    return order === "desc"
      ? (a, b) =>
          -servicePmsIdComparator(
            a as ServiceConfig | DefaultServiceConfig,
            b as ServiceConfig | DefaultServiceConfig
          )
      : (a, b) =>
          servicePmsIdComparator(
            a as ServiceConfig | DefaultServiceConfig,
            b as ServiceConfig | DefaultServiceConfig
          );
  } else if ("type" === orderBy) {
    return order === "desc"
      ? (a, b) => -serviceConfigTypeComparator(a as ServiceConfig, b as ServiceConfig, t)
      : (a, b) => serviceConfigTypeComparator(a as ServiceConfig, b as ServiceConfig, t);
  } else {
    return order === "desc"
      ? (a, b) => -stringIgnoreCaseComparator(a, b, orderBy as keyof T)
      : (a, b) => stringIgnoreCaseComparator(a, b, orderBy as keyof T);
  }
};

export const containsConflictRelatedToUnitGroupId = (
  conflicts: ServiceConfigConflict[],
  unitGroupId: string
) => {
  return conflicts && conflicts.filter((c) => c.relatedUnitGroupId === unitGroupId).length > 0;
};

export const containsConflictOfType = (
  conflicts: ServiceConfigConflict[],
  conflictType: ConflictType
) => {
  return conflicts && conflicts.filter((c) => c.type === conflictType).length > 0;
};

export const toTranslationProps = (
  conflict: ServiceConfigConflict,
  serviceConfig: ServiceConfig
) => {
  return {
    service: serviceConfig.service.details?.displayName || serviceConfig.service.serviceId || "",
    unitGroup:
      serviceConfig.unitGroups?.find((u) => u.unitGroupId === conflict.relatedUnitGroupId)?.details
        ?.name ||
      conflict.relatedUnitGroupId ||
      "",
    fieldName: conflict?.relatedFieldName || ""
  };
};

export const toServiceConfigurationInputContainer = (
  sandbox: ServiceConfigurationSandbox
): ServiceConfigurationInputContainer => {
  return {
    serviceConfigs: sandbox.serviceConfigs ? sandbox.serviceConfigs.map(toServiceConfigInput) : [],
    defaultServices: sandbox.defaultServices
      ? sandbox.defaultServices.map(toDefaultServiceConfigInput)
      : [],
    dependencyGroups: sandbox.dependencyGroups
      ? sandbox.dependencyGroups.map(toServiceDependencyGroupInput)
      : []
  };
};

export const toServiceConfigInput = (serviceConfig: ServiceConfig): ServiceConfigInput => {
  const { id, serviceConfigHash, conflicts, ...cleaned } = { ...serviceConfig };
  return {
    ...cleaned,
    service: {
      serviceId: cleaned.service.serviceId
    },
    unitGroups: cleaned.unitGroups?.map((u) => ({ unitGroupId: u.unitGroupId }))
  };
};

export const toDefaultServiceConfigInput = (
  defaultService: DefaultServiceConfig
): DefaultServiceConfigInput => {
  return {
    ...defaultService,
    service: {
      serviceId: defaultService.service.serviceId
    },
    unitGroups: defaultService.unitGroups?.map((u) => ({ unitGroupId: u.unitGroupId }))
  };
};

export const toServiceDependencyGroupInput = (
  dependencyGroup: ServiceDependencyGroup
): ServiceDependencyGroupInput => {
  return {
    ...dependencyGroup,
    services: dependencyGroup.services?.map((s) => ({ serviceId: s.serviceId }))
  };
};

export const enforceServiceConfigDefaults = (serviceConfig: ServiceConfig) => {
  const serviceConfigWithTaskDefaults = {
    ...serviceConfig,
    task: !!serviceConfig.task
      ? enforceTaskConfigDefaults(serviceConfig.task, serviceConfig.type)
      : null
  };
  switch (serviceConfig.type) {
    case ServiceConfigType.FreeCleaning:
      return enforceFreeCleaningServiceConfigDefaults(
        serviceConfigWithTaskDefaults as FreeCleaningServiceConfig
      );
    case ServiceConfigType.EarlyCheckin:
      return enforceEarlyCheckinServiceConfigDefaults(
        serviceConfigWithTaskDefaults as EarlyCheckinServiceConfig
      );
    case ServiceConfigType.LateCheckout:
      return enforceLateCheckoutServiceConfigDefaults(
        serviceConfigWithTaskDefaults as LateCheckoutServiceConfig
      );
    case ServiceConfigType.AddOn:
      return enforceAddonServiceConfigDefaults(serviceConfigWithTaskDefaults as AddOnServiceConfig);
    case ServiceConfigType.AdditionalCleaning:
      return enforceAdditionalCleaningServiceConfigDefaults(
        serviceConfigWithTaskDefaults as AdditionalCleaningServiceConfig
      );
  }
  return serviceConfigWithTaskDefaults;
};

export const enforceFreeCleaningServiceConfigDefaults = (
  serviceConfig: FreeCleaningServiceConfig
) => {
  return serviceConfig;
};

export const enforceEarlyCheckinServiceConfigDefaults = (
  serviceConfig: EarlyCheckinServiceConfig
) => {
  return {
    ...serviceConfig,
    reservationStatuses: [ReservationStatus.Confirmed],
    maximumBefore: null,
    bookingChannels: null,
    payAtCheckout: false
  };
};

export const enforceLateCheckoutServiceConfigDefaults = (
  serviceConfig: LateCheckoutServiceConfig
) => {
  return {
    ...serviceConfig,
    maximumBefore: null,
    bookingChannels: null,
    payAtCheckout: false
  };
};

export const enforceAddonServiceConfigDefaults = (serviceConfig: AddOnServiceConfig) => {
  return { ...serviceConfig, addOnType: serviceConfig.addOnType || AddOnType.Other };
};

export const enforceAdditionalCleaningServiceConfigDefaults = (
  serviceConfig: AdditionalCleaningServiceConfig
) => {
  return {
    ...serviceConfig,
    defaultDatePickerSelection:
      serviceConfig.defaultDatePickerSelection || DefaultDatePickerSelection.None,
    granularity: serviceConfig.granularity || ServiceConfigGranularity.AnyDayExceptArrivalDay
  };
};

export const enforceTaskConfigDefaults = (
  taskConfig: TaskConfig,
  serviceConfigType: ServiceConfigType
) => {
  switch (serviceConfigType) {
    case ServiceConfigType.FreeCleaning:
      return {
        ...taskConfig,
        housekeepingMainTask: true,
        createMultipleTasks: false,
        granularity: TaskGranularity.EveryDay,
        priority: null
      };
    case ServiceConfigType.EarlyCheckin:
    case ServiceConfigType.LateCheckout:
      return null;
  }

  return { ...taskConfig };
};

export const LIKE_MAGIC_FREE_CLEANING_ID = "likemagic_free_cleaning";
