import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import * as React from "react";
import { FC, useMemo, useRef } from "react";
import {
  ReservationConditionEnum,
  ReservationTableDto
} from "../../../domain/reservation-table-dto";
import {
  Chip,
  formatDate,
  formatTime,
  GuestFlowCheckpoint,
  ParagraphBold,
  ParagraphSmall,
  ParagraphSmallBold,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";

import { Box, Grid2, styled, Tooltip } from "@mui/material";
import { Circle, QuestionMark } from "@mui/icons-material";

import { ReservationStatus } from "../../../domain/reservation-status";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../hooks/use-property";
import { UnitCondition } from "../../../domain/Unit";
import { useUnitGroupById } from "../../../hooks/use-unit-group-by-id";
import { ReservationActionsMenu } from "../../../components/menu/reservation-actions-menu";
import { grey } from "@mui/material/colors";
import { computeDoNotDisturbLabel } from "../../../utils/reservation-utils";
import ReservationNotesPreview from "./reservation-note-preview";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CancelIcon from "@mui/icons-material/Cancel";
import HomeIcon from "@mui/icons-material/Home";
import { CrownSimpleIcon } from "src/icons/CrownSimpleIcon";
import { getI18nSelectedLanguage } from "src/utils/language";
import { PreCheckinProgress } from "../components/pre-checkin-progress/pre-checkin-progress";

interface TableRowProps {
  data: ReservationTableDto;
  onSelect: (reservationId: string) => void;
  selectedReservationId?: string;
}

export const mapUnitConditionColors = {
  [UnitCondition.CLEAN]: "success.main",
  [UnitCondition.CLEAN_TO_BE_INSPECTED]: "warning.main",
  [UnitCondition.DIRTY]: "error.main"
};

const mapReservationConditionColors = (condition: string) => {
  if (condition === ReservationConditionEnum.OK) {
    return "success";
  } else if (condition === ReservationConditionEnum.MINOR) {
    return "warning";
  } else if (condition === ReservationConditionEnum.CRITICAL) {
    return "error";
  } else {
    return "error";
  }
};

const styleForIcons = {
  color: grey[500],
  margin: "16px"
};

const displayReservationStatusIcon = (status: ReservationStatus) => {
  switch (status) {
    case ReservationStatus.CONFIRMED:
      return <CheckCircleIcon />;
    case ReservationStatus.IN_HOUSE:
      return <HomeIcon />;
    case ReservationStatus.NO_SHOW:
      return <VisibilityOffIcon />;
    case ReservationStatus.CHECKED_OUT:
      return <DirectionsRunIcon />;
    case ReservationStatus.DELETED:
      return <CancelIcon />;
    default:
      return <QuestionMark sx={{ styleForIcons }} />;
  }
};
/**
 * Only want to show room conditions is the reservation status is different than Deleted, No Show or Checked Out
 *
 * @param status reservation status
 */
const isRoomConditionNeeded = (status: string) =>
  !(
    status === ReservationStatus.CHECKED_OUT ||
    status === ReservationStatus.DELETED ||
    status === ReservationStatus.NO_SHOW
  );

const DoNotDisturbStyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0.5),
  paddingBottom: 0, // some weird 0.5 * theme.padding by default on the bottom
  marginBottom: theme.spacing(0.5), // and this compensates for the above
  border: 1,
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.error.light,
  backgroundColor: theme.palette.error.light,
  width: "fit-content"
}));
export const ReservationTableRow: FC<TableRowProps> = ({
  data,
  onSelect,
  selectedReservationId
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const assignedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    data.unit?.unitGroupId
  );
  const bookedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    data.bookedUnitGroupId
  );
  const { palette, spacing } = useTheme();

  const reservationActionsAnchorRef = useRef<SVGSVGElement>(null);
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  const conditionReasons = useMemo(
    () => {
      var reasons = data?.reservationConditionReasons
        ? Object.keys(data?.reservationConditionReasons).map((reason, idx) => (
            <Box
              key={`condition_reasons_${reason}-${idx}`}
              sx={{
                display: "flex",
                direction: "row",
                p: 1
              }}
            >
              <Circle
                fontSize="medium"
                sx={{
                  paddingRight: 1,
                  color: `${mapReservationConditionColors(
                    data?.reservationConditionReasons[reason]
                  )}.main`
                }}
              />
              <ParagraphSmall marginTop={0.5} color="common.white">
                {t(`labels__reservation_condition_${reason}`)}
              </ParagraphSmall>
            </Box>
          ))
        : [];

      if (features?.imsFeatureEnabled && data.id === "JDCLCBUC-1") {
        reasons.push(
          <Box
            key="condition_reasons_wifi-1"
            sx={{
              display: "flex",
              direction: "row",
              p: 1
            }}
          >
            <Circle
              fontSize="medium"
              sx={{
                paddingRight: 1,
                color: `${mapReservationConditionColors(
                  data?.reservationConditionReasons[
                    "GUEST_IN_HOUSE_BUT_NO_PRIVATE_ROOM_KEY_TECHNICAL_ISSUE"
                  ]
                )}.main`
              }}
            />
            <ParagraphSmall marginTop={0.5}>
              WiFi coverage unstable in room. Check with technician.
            </ParagraphSmall>
          </Box>
        );
      }
      return reasons;
    }, // No conditions
    [t, data?.reservationConditionReasons, data?.id, features?.imsFeatureEnabled]
  );

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={data.id}
      onClick={() => {
        onSelect(data.id);
      }}
      sx={{
        cursor: "pointer",
        boxShadow:
          data.reservationCondition === ReservationConditionEnum.CRITICAL
            ? `inset ${spacing(1)} 0 0 0 red`
            : null,
        backgroundColor: data.id === selectedReservationId ? palette.background.default : null
      }}
    >
      <TableCell sx={{ paddingLeft: spacing(4), paddingRight: "2px" }}>
        <Tooltip title={t(`labels__reservation_status_${data.reservationStatus}`)}>
          {displayReservationStatusIcon(data.reservationStatus)}
        </Tooltip>
      </TableCell>
      <TableCell sx={{ maxWidth: spacing(20) }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "no-wrap !important"
          }}
        >
          <Box>
            <ParagraphBold>{[data.firstName, data.lastName].join(" ")}</ParagraphBold>
            <ParagraphSmall color={palette.text.secondary}>
              {[
                data.adultsCount,
                /*@ts-ignore*/ `${t("labels__adult", {
                  count: data.adultsCount
                })}${data.childrenCount > 0 ? ", " : ""}`,
                data.childrenCount > 0
                  ? `${data.childrenCount} ${t("labels__child", {
                      /*@ts-ignore*/
                      count: data.childrenCount
                    })}`
                  : ""
              ].join(" ")}
            </ParagraphSmall>
            <ParagraphSmall color={grey[500]}>
              {t(`labels__reservation_status_${data.reservationStatus}`)}
            </ParagraphSmall>
          </Box>
          {data.primaryGuest.vipCode && (
            <Grid2>
              <Grid2
                container
                sx={{
                  alignItems: "center"
                }}
              >
                <Tooltip title={data.primaryGuest.vipCode} arrow placement="bottom">
                  <div>
                    <CrownSimpleIcon
                      sx={{ position: "relative", top: spacing(0.2), left: spacing(0.7) }}
                    />
                  </div>
                </Tooltip>
              </Grid2>
            </Grid2>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <ReservationNotesPreview
          notesList={data.notes}
          isRecurring={data.primaryGuest.isRecurring}
        />
      </TableCell>
      <TableCell>
        <PreCheckinProgress
          lastConfirmedPage={data.lastConfirmedPage as GuestFlowCheckpoint}
          secondScreenCheckpoint={data.secondScreenCheckpoint as GuestFlowCheckpoint}
          completed={data.completed}
          propertyId={data.propertyId}
        />
      </TableCell>
      <TableCell>
        <ParagraphSmall>
          {formatDate(
            data.checkInTime || data.estimatedArrivalTime || data.arrival,
            language,
            selectedProperty?.details.timeZone
          )}
        </ParagraphSmall>
        <ParagraphSmall sx={{ color: palette.text.secondary }}>
          {formatTime(
            data.checkInTime || data.estimatedArrivalTime || data.arrival,
            language,
            selectedProperty?.details.timeZone
          )}
        </ParagraphSmall>
      </TableCell>
      <TableCell>
        <ParagraphSmall>
          {formatDate(
            data.checkOutTime || data.estimatedDepartureTime || data.departure,
            language,
            selectedProperty?.details.timeZone
          )}
        </ParagraphSmall>
        <ParagraphSmall sx={{ color: palette.text.secondary }}>
          {formatTime(
            data.checkOutTime || data.estimatedDepartureTime || data.departure,
            language,
            selectedProperty?.details.timeZone
          )}
        </ParagraphSmall>
      </TableCell>
      <TableCell>
        <Grid2
          container
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {data?.unit?.name && (
            <Grid2>
              <ParagraphSmallBold>{data.unit.name}</ParagraphSmallBold>
            </Grid2>
          )}
          {data?.unit?.privacyMode && data.reservationStatus === ReservationStatus.IN_HOUSE && (
            <Grid2>
              <Tooltip
                title={computeDoNotDisturbLabel(t, data?.unit?.privacyModeLastChangeTimestamp)}
              >
                <DoNotDisturbStyledBox>
                  <DoNotDisturbOnOutlinedIcon sx={{ color: "error.main" }} fontSize={"small"} />
                </DoNotDisturbStyledBox>
              </Tooltip>
            </Grid2>
          )}
        </Grid2>

        <ParagraphSmall color={palette.text.secondary}>
          {assignedUnitGroup?.name || bookedUnitGroup?.name}
        </ParagraphSmall>
        {data?.unit?.unitId &&
          data?.unit?.condition &&
          isRoomConditionNeeded(data.reservationStatus) && (
            <ParagraphSmall
              color={
                mapUnitConditionColors[
                  data?.unit?.condition as keyof typeof mapUnitConditionColors
                ] || "text.primary"
              }
            >
              {t(`labels__unit_condition_${data?.unit?.condition}`)}
            </ParagraphSmall>
          )}
      </TableCell>
      <TableCell>
        <Tooltip title={conditionReasons} disableHoverListener={!conditionReasons.length}>
          <span>
            <Chip
              color={mapReservationConditionColors(data.reservationCondition)}
              label={t(`labels__condition_${data.reservationCondition}`)}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell onClick={(event) => event.stopPropagation()}>
        <ReservationActionsMenu reservation={data} anchorRef={reservationActionsAnchorRef} />
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
