import React, { FC } from "react";
import { FormControl, FormHelperText, Grid2, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Input, ParagraphBold, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import {
  DistributionRule,
  FreeCleaningServiceConfig,
  WeekendRule
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface FreeCleaningFieldsProps {}

export const FreeCleaningFields: FC<FreeCleaningFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange } = useFormikContext<FreeCleaningServiceConfig>();

  const weekendRuleOptions = Object.values(WeekendRule).map((type) => ({
    label: t("labels__settings_service_config_weekendrule_" + type),
    value: type
  }));

  const distributionRuleOptions = Object.values(DistributionRule).map((type) => ({
    label: t("labels__settings_service_config_distributionrule_" + type),
    value: type
  }));

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_freecleaning_distributionrule")} *
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_freecleaning_distributionrule_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="distributionRule"
              name="distributionRule"
              variant="outlined"
              value={values.distributionRule ?? ""}
              options={distributionRuleOptions}
              onChange={handleChange}
              error={!!errors.distributionRule}
              disabled={!canEditServiceConfig}
            />
            <FormHelperText error required>
              {errors.distributionRule?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <Grid2 size={isMobile ? 12 : 6}>
          {values.distributionRule &&
            values.distributionRule === DistributionRule.ArrivalWeekDay && (
              <>
                <ParagraphBold>
                  {t("labels__service_config_form_freecleaning_weekendrule")} *
                </ParagraphBold>
                <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
                  {t("labels__service_config_form_freecleaning_weekendrule_description")}
                </ParagraphSmall>
                <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
                  <Select
                    id="weekendRule"
                    name="weekendRule"
                    variant="outlined"
                    value={values.weekendRule ?? ""}
                    options={weekendRuleOptions}
                    onChange={handleChange}
                    error={!!errors.weekendRule}
                    disabled={!canEditServiceConfig}
                  />
                  <FormHelperText error required>
                    {errors.weekendRule?.toString()}
                  </FormHelperText>
                </FormControl>
              </>
            )}
        </Grid2>
        {values.distributionRule && values.distributionRule !== DistributionRule.ArrivalWeekDay && (
          <>
            <Grid2 size={isMobile ? 12 : 6}>
              <ParagraphBold>
                {t("labels__service_config_form_freecleaning_minimum_nights")} *
              </ParagraphBold>
              <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
                {t("labels__service_config_form_freecleaning_minimum_nights_description")}
              </ParagraphSmall>
              <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
                <Input
                  id="minimumNights"
                  name="minimumNights"
                  variant="outlined"
                  value={values.minimumNights ?? ""}
                  onChange={handleChange}
                  type={"number"}
                  inputMode={"numeric"}
                  error={!!errors.minimumNights}
                  disabled={!canEditServiceConfig}
                  sx={{
                    ".MuiInputBase-input": {
                      paddingY: theme.spacing(2)
                    }
                  }}
                />
                <FormHelperText error required>
                  {errors.minimumNights?.toString()}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 size={isMobile ? 12 : 6}>
              <ParagraphBold>
                {t("labels__service_config_form_freecleaning_interval")} *
              </ParagraphBold>
              <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
                {t("labels__service_config_form_freecleaning_interval_description")}
              </ParagraphSmall>
              <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
                <Input
                  id="interval"
                  name="interval"
                  variant="outlined"
                  value={values.interval ?? ""}
                  onChange={handleChange}
                  type={"number"}
                  inputMode={"decimal"}
                  error={!!errors.interval}
                  disabled={!canEditServiceConfig}
                  sx={{
                    ".MuiInputBase-input": {
                      paddingY: theme.spacing(2)
                    }
                  }}
                />
                <FormHelperText error required>
                  {errors.interval?.toString()}
                </FormHelperText>
              </FormControl>
            </Grid2>
          </>
        )}
      </Grid2>
    </>
  );
};
