import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme
} from "@mui/material";
import { useServiceConfigModal } from "./use-service-config-modal";
import { Button, Heading3, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Form, Formik } from "formik";
import {
  ServiceConfig,
  ServiceConfigurationContainer
} from "../../../../graphql-tenantconfig/generated/graphql";
import { Params } from "../../../../hooks/use-select-id";
import { ServiceConfigForm } from "./service-config-form";
import { useServiceConfigFormValidations } from "./use-service-config-form-validations";
import { Notification } from "../../../../components/notification";
import {
  enforceServiceConfigDefaults,
  LIKE_MAGIC_FREE_CLEANING_ID,
  toTranslationProps
} from "../service-config-utils";
import CloseIcon from "@mui/icons-material/Close";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";

interface ServiceConfigModalProps {
  serviceConfigs: Array<ServiceConfig>;
  serviceConfiguration: ServiceConfigurationContainer;
  onSubmit: (serviceConfig: ServiceConfig) => void;
  onDelete: (serviceConfigId: string) => void;
}

export const ServiceConfigModal: FC<ServiceConfigModalProps> = ({
  serviceConfigs,
  serviceConfiguration,
  onSubmit,
  onDelete
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { serviceConfigFormValidation } = useServiceConfigFormValidations();
  const { serviceConfigId, onClose } = useServiceConfigModal();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const isCreateNew = useMemo(() => {
    return serviceConfigId === Params.CREATE_SERVICE_CONFIG_PARAM;
  }, [serviceConfigId]);

  const serviceConfig = useMemo(() => {
    return serviceConfigs.find((serviceConfig) => serviceConfig.id === serviceConfigId);
  }, [serviceConfigs, serviceConfigId]);

  const initalValues: ServiceConfig = useMemo(() => {
    return serviceConfig
      ? enforceServiceConfigDefaults(serviceConfig)
      : ({ type: null, service: null } as any as ServiceConfig);
  }, [serviceConfig]);

  const onSubmitForm = useCallback(
    (values: ServiceConfig) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  const onDeleteServiceConfig = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);

  const [showJson, setShowJson] = useState<boolean>(false);

  return (
    <Dialog
      open={!!serviceConfigId && (!!serviceConfig || isCreateNew)}
      onClose={onClose}
      maxWidth={showDeleteConfirmation ? "xs" : "lg"}
      PaperProps={{
        sx: {
          display: "flex",
          width: "100%"
        }
      }}
    >
      {showDeleteConfirmation ? (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <>{t("labels__settings_service_config_delete_service_config_confirmation_title")}</>
              <IconButton onClick={() => setShowDeleteConfirmation(false)} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Paragraph>
              <span>
                {t("labels__settings_service_config_delete_service_config_confirmation_msg1", {
                  name:
                    serviceConfig?.service?.details?.displayName ||
                    serviceConfig?.service?.serviceId ||
                    ""
                })}
              </span>
              <span style={{ color: theme.palette.error.main }}>
                {t("labels__settings_service_config_delete_service_config_confirmation_msg2")}
              </span>
            </Paragraph>
          </DialogContent>
          <DialogActions>
            <Box sx={{ width: "100%", px: theme.spacing(2), pb: theme.spacing(2) }}>
              <Button
                variant="primary"
                color="error"
                fullWidth
                onClick={() => {
                  onDelete(serviceConfig!.id);
                  setShowDeleteConfirmation(false);
                  onClose();
                }}
              >
                {t(
                  "labels__settings_service_config_delete_service_config_confirmation_button_delete"
                )}
              </Button>
              <Button
                variant="secondary"
                fullWidth
                sx={{ mt: theme.spacing(2) }}
                onClick={() => setShowDeleteConfirmation(false)}
              >
                {t("buttons__cancel")}
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : (
        <Formik
          initialValues={initalValues}
          onSubmit={onSubmitForm}
          validationSchema={serviceConfigFormValidation}
          validateOnChange={!isCreateNew}
          validateOnMount={!isCreateNew}
        >
          {(formik) => {
            return (
              <>
                <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box>
                      <Heading3>
                        {isCreateNew
                          ? t("labels__create_service_config")
                          : LIKE_MAGIC_FREE_CLEANING_ID === serviceConfig?.service.serviceId
                            ? t("labels__service_config_likemagic_free_cleaning")
                            : serviceConfig?.service.details?.displayName ||
                              serviceConfig?.service.serviceId}
                      </Heading3>
                    </Box>
                    <Box>
                      <ParagraphSmall>
                        {t("labels__service_config_modal_description")}
                      </ParagraphSmall>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      variant="ghost"
                      size="small"
                      color="info"
                      onClick={() => setShowJson(!showJson)}
                    >
                      {showJson
                        ? t("buttons__service_config_hide_json")
                        : t("buttons__service_config_show_json")}
                    </Button>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  {showJson ? (
                    <pre style={{ fontSize: "0.75rem" }}>
                      {JSON.stringify(formik.values, null, 2)}
                    </pre>
                  ) : (
                    <>
                      {serviceConfig &&
                        serviceConfig.conflicts &&
                        serviceConfig.conflicts.map((conflict, index) => (
                          <Box
                            key={`service-config-modal-conflict-${serviceConfig.id}-${index}`}
                            sx={{ mb: theme.spacing(2) }}
                          >
                            <Notification
                              title={t(
                                "labels__settings_service_config_conflict_info_" + conflict.type,
                                toTranslationProps(conflict, serviceConfig)
                              )}
                              type="error"
                            ></Notification>
                          </Box>
                        ))}
                      <Form noValidate id="ServiceConfigForm" style={{ width: "100%" }}>
                        <ServiceConfigForm
                          serviceConfiguration={serviceConfiguration}
                          conflicts={serviceConfig?.conflicts ?? []}
                          isCreateNew={isCreateNew}
                        />
                      </Form>
                    </>
                  )}
                </DialogContent>
                {!showJson && (
                  <>
                    {canEditServiceConfig ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          px: 3,
                          py: 2
                        }}
                      >
                        <DialogActions sx={{ p: 0 }}>
                          {serviceConfig && serviceConfig.id && (
                            <Button
                              size="large"
                              variant="secondary"
                              color="error"
                              onClick={(event) => onDeleteServiceConfig()}
                              fullWidth
                              sx={{ mt: theme.spacing(2), mr: 1 }}
                            >
                              {t("labels__delete")}
                            </Button>
                          )}
                        </DialogActions>
                        <DialogActions sx={{ p: 0 }}>
                          <Button
                            size="large"
                            variant="ghost"
                            onClick={(event) => onClose()}
                            fullWidth
                            sx={{ mt: theme.spacing(2), mr: 1 }}
                          >
                            {t("buttons__cancel")}
                          </Button>
                          <Button
                            type="submit"
                            variant="primary"
                            size="large"
                            fullWidth
                            sx={{ marginTop: theme.spacing(2) }}
                            onClick={() => formik.handleSubmit()}
                            disabled={!formik.dirty}
                          >
                            {t("buttons__save")}
                          </Button>
                        </DialogActions>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                          px: 3,
                          py: 2
                        }}
                      >
                        <DialogActions sx={{ p: 0 }}>
                          <Button
                            size="large"
                            variant="primary"
                            onClick={(event) => onClose()}
                            fullWidth
                            sx={{ mt: theme.spacing(2), mr: 1 }}
                          >
                            {t("buttons__close")}
                          </Button>
                        </DialogActions>
                      </Box>
                    )}
                  </>
                )}
              </>
            );
          }}
        </Formik>
      )}
    </Dialog>
  );
};
