import { FC, useState } from "react";
import { ServiceDependencyGroup } from "../../../graphql-tenantconfig/generated/graphql";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { WarningAmber } from "@mui/icons-material";

interface DependencyGroupsTableRowProps {
  data: ServiceDependencyGroup;
  onSelect?: (obj: ServiceDependencyGroup) => void;
}

const DependencyGroupsTableRow: FC<DependencyGroupsTableRowProps> = ({ data, onSelect }) => {
  const { spacing } = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow hover onClick={() => onSelect && onSelect(data)}>
        <TableCell sx={{ paddingLeft: spacing(1), borderBottomWidth: open ? 0 : 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
            sx={{ marginRight: spacing(1) }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {data.conflicts && data.conflicts.length > 0 && (
            <Tooltip title={data.conflicts.map((c) => c.type).join("\n")}>
              <WarningAmber color="error" sx={{ mb: -0.8, mr: 1 }} />
            </Tooltip>
          )}
          <b>{data.key}</b>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}></TableCell>
      </TableRow>
      {data.services &&
        data.services.map((service, index) => (
          <TableRow key={`dependency-group-service-${data.key}-${service.serviceId}`}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.services!.length - 1 ? 1 : 0
              }}
              sx={{ paddingLeft: spacing(6.5) }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: spacing(index === data.services!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  {data.conflicts &&
                    data.conflicts.filter((c) => c.relatedServiceId === service.serviceId).length >
                      0 && (
                      <Tooltip
                        title={data.conflicts
                          .filter((c) => c.relatedServiceId === service.serviceId)
                          .map((c) => c.type)
                          .join("\n")}
                      >
                        <WarningAmber color="error" sx={{ mb: -0.8, mr: 1 }} />
                      </Tooltip>
                    )}
                  {service.details?.name || service.serviceId}
                </Box>
              </Collapse>
            </TableCell>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.services!.length - 1 ? 1 : 0
              }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: spacing(index === data.services!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  {service.serviceId}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default DependencyGroupsTableRow;
