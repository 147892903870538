import React, { FC, useCallback } from "react";
import { FormControl, Grid2, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Input, ParagraphBold, ParagraphSmall, TimePicker } from "@likemagic-tech/sv-magic-library";
import { LateCheckoutServiceConfig } from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { BaseServiceConfigFields } from "./base-service-config-fields";
import { format, parse } from "date-fns";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface LateCheckoutFieldsProps {}

export const LateCheckoutFields: FC<LateCheckoutFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, setFieldValue } = useFormikContext<LateCheckoutServiceConfig>();

  const onChangeMaximumCheckoutTime = useCallback(
    (time: Date | null) => {
      if (!!time) {
        setFieldValue("maximumCheckoutTime", format(time, "HH:mm:ss"));
      } else {
        setFieldValue("maximumCheckoutTime", null);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_latecheckout_maximumcheckouttime")} *
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_latecheckout_maximumcheckouttime_description")}
          </ParagraphSmall>
          <FormControl
            fullWidth={true}
            sx={{
              mt: theme.spacing(0.75),
              "& .MuiInputBase-input": {
                paddingY: `${theme.spacing(2)} !important`
              }
            }}
          >
            {canEditServiceConfig ? (
              <TimePicker
                value={
                  values.maximumCheckoutTime
                    ? parse(values.maximumCheckoutTime, "HH:mm:ss", new Date())
                    : null
                }
                onChange={onChangeMaximumCheckoutTime}
                label=""
                variant="outlined"
                error={errors.maximumCheckoutTime?.toString() ?? ""}
              />
            ) : (
              <Input
                value={values.maximumCheckoutTime}
                label=""
                variant="outlined"
                error={errors.maximumCheckoutTime?.toString() ?? ""}
                disabled={true}
              />
            )}
          </FormControl>
        </Grid2>
        <BaseServiceConfigFields />
      </Grid2>
    </>
  );
};
