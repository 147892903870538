import { useDispatch, useSelector } from "../store";
import {
  addOrDeleteServiceConfig,
  deleteServiceConfig,
  selectServiceConfigurationSandboxContainer
} from "./service-configuration-sandbox";
import { useGetServiceConfigurationQueryEnhanced } from "../graphql-tenantconfig/queries/enhanced-queries/get-service-configuration-enhanced";
import { useCallback, useEffect } from "react";
import { useValidateServiceConfigurationMutationEnhanced } from "../graphql-tenantconfig/mutations/enhanced-mutations/validate-service-configuration-enhanced";
import { ServiceConfig } from "../graphql-tenantconfig/generated/graphql";
import { useMergeServiceConfigurationMutationEnhanced } from "../graphql-tenantconfig/mutations/enhanced-mutations/merge-service-configuration-enhanced";
import { useProperty } from "../hooks/use-property";
import { toServiceConfigurationInputContainer } from "../pages/settings/settings-additional-services/service-config-utils";

export const useServiceConfigurationSandbox = () => {
  const { selectedProperty } = useProperty();
  const container = useSelector((state) =>
    selectServiceConfigurationSandboxContainer(state, selectedProperty.propertyId)
  );
  const { data } = useGetServiceConfigurationQueryEnhanced(
    { pmsPropertyId: selectedProperty.propertyId },
    {}
  );
  const [validateServiceConfigurationAction] = useValidateServiceConfigurationMutationEnhanced();
  const [mergeServiceConfigurationAction] = useMergeServiceConfigurationMutationEnhanced();
  const dispatch = useDispatch();

  const onDeleteServiceConfig = useCallback(
    (serviceConfigId: string) => {
      dispatch(
        deleteServiceConfig({
          pmsPropertyId: selectedProperty.propertyId,
          serviceConfigId: serviceConfigId
        })
      );
    },
    [selectedProperty, dispatch]
  );

  const onAddOrReplaceServiceConfig = useCallback(
    (serviceConfig: ServiceConfig) => {
      dispatch(
        addOrDeleteServiceConfig({
          pmsPropertyId: selectedProperty.propertyId,
          serviceConfig: serviceConfig
        })
      );
    },
    [selectedProperty, dispatch]
  );

  const onPublishServiceConfiguration = useCallback(() => {
    if (container && container.dirty && container.validated && container.sandbox) {
      mergeServiceConfigurationAction({
        pmsPropertyId: selectedProperty.propertyId,
        serviceConfiguration: toServiceConfigurationInputContainer(container.sandbox)
      });
    }
  }, [selectedProperty, container, mergeServiceConfigurationAction]);

  useEffect(() => {
    if (!!container && !!container.sandbox && container.dirty && !container.validated) {
      validateServiceConfigurationAction({
        pmsPropertyId: selectedProperty.propertyId,
        serviceConfiguration: toServiceConfigurationInputContainer(container.sandbox)
      });
    }
  }, [selectedProperty, container, validateServiceConfigurationAction]);

  return {
    data: data,
    sandbox: container.sandbox,
    dirty: container.dirty,
    published: container.published,
    validated: container.validated,
    deleteServiceConfig: onDeleteServiceConfig,
    addOrReplaceServiceConfig: onAddOrReplaceServiceConfig,
    publishServiceConfiguration: onPublishServiceConfiguration
  };
};
