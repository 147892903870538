import { Collapse, Grid2 } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Button, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { HousekeepingCard, HousekeepingCardType } from "./housekeeping-card/housekeeping-card";
import * as React from "react";
import { FC, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

export enum HousekeepingCardsTypeEnum {
  UNITS = "UNITS",
  RESERVATIONS = "RESERVATIONS"
}
interface HousekeepingCardsFloorProps {
  floorKey?: string;
  units?: Array<any>;
  reservations?: Array<any>;
  housekeepingCardsType: HousekeepingCardsTypeEnum;
  propertyId: string;
}

export const HousekeepingCardsFloor: FC<HousekeepingCardsFloorProps> = ({
  floorKey,
  units,
  housekeepingCardsType,
  reservations,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const [floorExpanded, setFloorExpanded] = useState(true);

  const mapHousekeepingCards = useMemo(() => {
    switch (housekeepingCardsType) {
      case HousekeepingCardsTypeEnum.UNITS:
        return (
          units?.map((unit) => ({
            id: unit?.pmsUnitId,
            unit: unit
          })) || []
        );
      case HousekeepingCardsTypeEnum.RESERVATIONS:
        return (
          reservations?.map((reservation) => ({
            id: reservation?.pmsReservationId,
            reservation: reservation
          })) || []
        );
      default:
        return [];
    }
  }, [units, reservations, housekeepingCardsType]);

  const floorTitle = useMemo(() => {
    return floorKey === String(null) ? t("labels__units") : `${t("labels__floor")} ${floorKey}`;
  }, [floorKey, t]);

  return (
    <Grid2 container sx={{ width: "100%" }}>
      <Grid2
        container
        sx={{
          gap: 0.5,
          alignItems: "center",
          pb: 1
        }}
      >
        <Button
          variant="ghost"
          onClick={() => setFloorExpanded(!floorExpanded)}
          size="small"
          disablePadding
        >
          {floorExpanded ? (
            <ExpandLess fontSize="small" sx={{ color: grey[600] }} />
          ) : (
            <ExpandMore fontSize="small" sx={{ color: grey[600] }} />
          )}
          {housekeepingCardsType === HousekeepingCardsTypeEnum.UNITS ? (
            <ParagraphSmall color={grey[600]}>{floorTitle}</ParagraphSmall>
          ) : (
            <ParagraphSmall color={grey[600]}>{t("labels__unassigned_units")}</ParagraphSmall>
          )}
        </Button>
      </Grid2>
      <Collapse in={floorExpanded} sx={{ width: "100%" }}>
        <Grid2
          container
          spacing={2}
          sx={{
            pb: 4
          }}
        >
          {mapHousekeepingCards.map((housekeepingCard: HousekeepingCardType) => (
            <HousekeepingCard
              key={housekeepingCard.id}
              housekeepingCard={housekeepingCard}
              propertyId={propertyId}
              housekeepingCardsType={housekeepingCardsType}
            />
          ))}
        </Grid2>
      </Collapse>
    </Grid2>
  );
};
