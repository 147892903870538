import React, { FC, useCallback, useMemo } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid2,
  Switch,
  useTheme
} from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Heading3, ParagraphBold, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import {
  ConflictType,
  ServiceConfig,
  ServiceConfigConflict,
  ServiceConfigType,
  ServiceConfigurationContainer,
  TaskConfig
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { MultipleUnitGroupInput } from "./multiple-unitgroup-input";
import { SingleServiceInput } from "./single-service-input";
import { FreeCleaningFields } from "./free-cleaning-fields";
import { TaskFields } from "./task-fields";
import { WarningAmber } from "@mui/icons-material";
import {
  containsConflictOfType,
  enforceServiceConfigDefaults,
  enforceTaskConfigDefaults
} from "../service-config-utils";
import { EarlyCheckinFields } from "./early-checkin-fields";
import { LateCheckoutFields } from "./late-checkout-fields";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { AddonFields } from "./addon-fields";
import { AdditionalCleaningFields } from "./additional-cleaning-fields";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface ServiceConfigFormProps {
  serviceConfiguration: ServiceConfigurationContainer;
  isCreateNew: boolean;
  conflicts: ServiceConfigConflict[];
}

export const ServiceConfigForm: FC<ServiceConfigFormProps> = ({
  serviceConfiguration,
  isCreateNew,
  conflicts
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, setFieldValue, setValues } = useFormikContext<ServiceConfig>();

  const serviceConfigTypeOptions = Object.values(ServiceConfigType).map((type) => ({
    label: t("labels__settings_service_config_type_" + type),
    value: type
  }));

  const currentFieldsComponent = useMemo(() => {
    switch (values.type) {
      case ServiceConfigType.FreeCleaning:
        return <FreeCleaningFields />;
      case ServiceConfigType.EarlyCheckin:
        return <EarlyCheckinFields />;
      case ServiceConfigType.LateCheckout:
        return <LateCheckoutFields />;
      case ServiceConfigType.AddOn:
        return <AddonFields />;
      case ServiceConfigType.AdditionalCleaning:
        return <AdditionalCleaningFields />;
    }
  }, [values.type]);

  const taskCreationAllowed = useMemo(() => {
    return (
      ServiceConfigType.EarlyCheckin !== values.type &&
      ServiceConfigType.LateCheckout !== values.type
    );
  }, [values.type]);

  const onToggleCreateTask = useCallback(
    (createTask: boolean) => {
      if (createTask) {
        const taskConfig = enforceTaskConfigDefaults(
          { housekeepingMainTask: true, createMultipleTasks: true } as TaskConfig,
          values.type
        );
        setFieldValue("task", taskConfig);
      } else {
        setFieldValue("task", null);
      }
    },
    [setFieldValue, values.type]
  );

  const onChangeServiceConfigType = useCallback(
    async (type: ServiceConfigType) => {
      if (!!type) {
        // enforce defaults
        await setValues(enforceServiceConfigDefaults({ ...values, type: type }));

        // automatically set service id to fixed one in case there is a fixed one returned from be
        if (
          ServiceConfigType.FreeCleaning === type &&
          !!serviceConfiguration.fixedFreeCleaningServiceId &&
          values.service?.serviceId !== serviceConfiguration.fixedFreeCleaningServiceId
        ) {
          setFieldValue("service", {
            serviceId: serviceConfiguration.fixedFreeCleaningServiceId
          });
        }

        // reset service in case fixed free cleaning id is selected and type is not free cleaning
        if (
          ServiceConfigType.FreeCleaning !== type &&
          !!serviceConfiguration.fixedFreeCleaningServiceId &&
          values.service?.serviceId &&
          values.service?.serviceId === serviceConfiguration.fixedFreeCleaningServiceId
        ) {
          setFieldValue("service", null);
        }
      }
    },
    [setFieldValue, setValues, values, serviceConfiguration?.fixedFreeCleaningServiceId]
  );

  const unitGroupValidationErrors = useMemo(() => {
    if (errors?.unitGroups) {
      if (errors?.unitGroups && typeof errors?.unitGroups === "string") {
        return errors.unitGroups as string;
      } else if (Array.isArray(errors?.unitGroups)) {
        const allerrors = errors.unitGroups
          .filter((e) => !!e)
          .map((e) => e.unitGroupId?.toString());
        return allerrors ? allerrors[0] : "";
      }
    }
    return "";
  }, [errors.unitGroups]);

  const selectablePmsServices = useMemo(() => {
    if (
      ServiceConfigType.FreeCleaning === values.type &&
      !!serviceConfiguration.fixedFreeCleaningServiceId
    ) {
      return [{ pmsId: serviceConfiguration.fixedFreeCleaningServiceId }];
    } else {
      return serviceConfiguration.pmsServices ?? [];
    }
  }, [
    values.type,
    serviceConfiguration.fixedFreeCleaningServiceId,
    serviceConfiguration.pmsServices
  ]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_type")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_type_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="type"
              name="type"
              variant="outlined"
              value={values.type ?? ""}
              onChange={(event) =>
                onChangeServiceConfigType(event?.target?.value as ServiceConfigType)
              }
              renderValue={(value) => t("labels__settings_service_config_type_" + value)}
              options={serviceConfigTypeOptions}
              disabled={
                !canEditServiceConfig ||
                (!isCreateNew &&
                  !containsConflictOfType(conflicts, ConflictType.InvalidFreeCleaningServiceId))
              }
              error={!!errors.type}
              defaultValue={null}
            />
            <FormHelperText error required>
              {errors.type?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_service")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_service_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <SingleServiceInput
              value={values.service}
              options={selectablePmsServices}
              setValue={(newValue) => {
                setFieldValue("service", newValue);
              }}
              error={!!errors.service}
              disabled={
                !canEditServiceConfig ||
                (isCreateNew && !values.type) ||
                (ServiceConfigType.FreeCleaning === values.type &&
                  !!serviceConfiguration.fixedFreeCleaningServiceId &&
                  values.service?.serviceId === serviceConfiguration.fixedFreeCleaningServiceId)
              }
            />
            <FormHelperText error required>
              {errors.service?.serviceId?.toString() || errors.service?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        {(values.service || !isCreateNew) && (
          <Grid2 size={12}>
            <ParagraphBold>{t("labels__service_config_form_unitgroups")} *</ParagraphBold>
            <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
              {t("labels__service_config_form_unitgroups_description")}
            </ParagraphSmall>
            <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
              <MultipleUnitGroupInput
                value={values.unitGroups ?? []}
                options={serviceConfiguration?.pmsUnitGroups ?? []}
                setValue={(newValue) => {
                  setFieldValue("unitGroups", newValue);
                }}
                error={!!errors.unitGroups}
                conflicts={conflicts}
                disabled={!canEditServiceConfig}
              />
              <FormHelperText error required>
                {unitGroupValidationErrors}
              </FormHelperText>
            </FormControl>
          </Grid2>
        )}
      </Grid2>

      {values.type && (values.service || !isCreateNew) && (
        <>
          <Box sx={{ mt: theme.spacing(3), width: "100%" }}>
            {currentFieldsComponent ? (
              currentFieldsComponent
            ) : (
              <Heading3>
                <WarningAmber sx={{ mb: -0.5, mr: 2 }} />
                {values.type} TO BE IMPLEMENTED
                <WarningAmber sx={{ mb: -0.5, ml: 2 }} />
              </Heading3>
            )}
          </Box>

          {taskCreationAllowed && (
            <>
              <Heading3 sx={{ mt: theme.spacing(3) }}>
                {t("labels__service_config_form_task")}
              </Heading3>

              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={!!values.task} />}
                  onChange={(_, toggled) => onToggleCreateTask(toggled)}
                  label={t("labels__service_config_form_task_switch")}
                  disabled={!canEditServiceConfig}
                />
              </FormGroup>

              {values.task && <TaskFields />}
            </>
          )}
        </>
      )}
    </>
  );
};
