import React, { useCallback, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button, EmptyState, Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import AdditionalServicesTable from "./additional-services-table";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme
} from "@mui/material";
import DefaultServicesTable from "./default-services-table";
import DependencyGroupsTable from "./dependency-groups-table";
import {
  DefaultServiceConfig,
  ServiceConfig,
  ServiceDependencyGroup
} from "../../../graphql-tenantconfig/generated/graphql";
import { DrawerDetails } from "../../../components/drawer-details/drawer-details";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import Plus from "../../../icons/Plus";
import { ServiceConfigModal } from "./service-config-modal/service-config-modal";
import { useServiceConfigModal } from "./service-config-modal/use-service-config-modal";
import { Notification } from "../../../components/notification";
import { useServiceConfigurationSandbox } from "../../../slices/use-service-configuration-sandbox";
import CloseIcon from "@mui/icons-material/Close";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { useCanEditServiceConfig } from "./use-can-edit-service-config";

const ServiceConfigurationOverview = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();
  const { openNewServiceConfig } = useServiceConfigModal();

  const {
    data,
    sandbox,
    dirty,
    published,
    deleteServiceConfig,
    addOrReplaceServiceConfig,
    publishServiceConfiguration
  } = useServiceConfigurationSandbox();

  // TODO: remove once drawer is obsolete
  const [selectedObject, setSelectedObject] = useState<{
    title: string;
    object: ServiceConfig | DefaultServiceConfig | ServiceDependencyGroup;
  }>();

  const onSelectObject = useCallback(
    (title: string, obj: ServiceConfig | DefaultServiceConfig | ServiceDependencyGroup) => {
      setSelectedObject({ title: title, object: obj });
    },
    [setSelectedObject]
  );

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [serviceConfigToDelete, setServiceConfigToDelete] = useState<ServiceConfig>();
  const onDeleteServiceConfig = useCallback(
    (serviceConfig: ServiceConfig) => {
      setServiceConfigToDelete(serviceConfig);
      setDeleteConfirmDialogOpen(true);
    },
    [setServiceConfigToDelete, setDeleteConfirmDialogOpen]
  );

  const headerNotificationComponent = useMemo(() => {
    if (sandbox?.conflicts && sandbox?.conflicts?.length > 0) {
      return (
        <Notification
          title={t("labels__settings_service_config_conflict_error", {
            count: sandbox.conflicts.length.toString()
          })}
          type="error"
        />
      );
    } else if (published) {
      return <Notification title={t("labels__settings_service_config_published")} type="success" />;
    } else if (dirty) {
      return (
        <Notification
          title={t("labels__settings_service_config_ready_to_publish")}
          type="warning"
        />
      );
    } else {
      return (
        <Notification
          title={t("labels__settings_service_config_initial_no_conflict")}
          type="info"
        />
      );
    }
  }, [sandbox, dirty, published, t]);

  return (
    <>
      {sandbox && (
        <>
          <Box sx={{ mb: theme.spacing(2), display: "flex" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  ".MuiPaper-root": {
                    py: 0.25
                  },
                  ".MuiAlert-icon": {
                    py: 0
                  }
                }}
              >
                {headerNotificationComponent}
              </Box>
            </Box>
            {canEditServiceConfig && (
              <Box sx={{ flexGrow: 0, pl: theme.spacing(1) }}>
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  fullWidth
                  onClick={publishServiceConfiguration}
                  disabled={!dirty || sandbox.conflicts?.length > 0}
                  sx={{ borderRadius: "6px" }}
                >
                  {t("buttons__publish_service_configuration")}
                </Button>
              </Box>
            )}
          </Box>

          <Paper sx={{ width: "100%", mb: 2, borderRadius: isMobile ? 0 : 2 }} elevation={0}>
            <Box
              sx={{
                p: 2,
                pb: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Heading3>{t("labels__settings_service_config_heading_service_configs")}</Heading3>
              {canEditServiceConfig && (
                <Button
                  variant="secondary"
                  size="medium"
                  startIcon={<Plus />}
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={openNewServiceConfig}
                >
                  {t("buttons__add_service_config")}
                </Button>
              )}
            </Box>
            {sandbox.serviceConfigs && sandbox.serviceConfigs.length > 0 ? (
              <AdditionalServicesTable
                data={sandbox.serviceConfigs}
                onDelete={onDeleteServiceConfig}
              />
            ) : (
              <Box sx={{ pb: 1 }}>
                <EmptyState title={t("labels__settings_service_config_empty_service_configs")} />
              </Box>
            )}
          </Paper>

          {data?.GetServiceConfiguration?.supportsDefaultServices && (
            <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
              <Heading3 sx={{ p: 2, pb: 0 }}>
                {t("labels__settings_service_config_heading_default_services")}
              </Heading3>
              {sandbox.defaultServices && sandbox.defaultServices.length > 0 ? (
                <DefaultServicesTable
                  data={sandbox.defaultServices}
                  onSelect={(obj) =>
                    onSelectObject(
                      `Default Service Config for ${
                        obj.service.details?.displayName || obj.service.serviceId
                      }`,
                      obj
                    )
                  }
                />
              ) : (
                <Box sx={{ pb: 1 }}>
                  <EmptyState title={t("labels__settings_service_config_empty_default_services")} />
                </Box>
              )}
            </Paper>
          )}

          <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
            <Heading3 sx={{ p: 2, pb: 0 }}>
              {t("labels__settings_service_config_heading_dependency_groups")}
            </Heading3>
            {sandbox.dependencyGroups && sandbox.dependencyGroups.length > 0 ? (
              <DependencyGroupsTable
                data={sandbox.dependencyGroups}
                onSelect={(obj) => onSelectObject(`Dependency Group ${obj.key}`, obj)}
              />
            ) : (
              <Box sx={{ pb: 1 }}>
                <EmptyState title={t("labels__settings_service_config_empty_dependency_groups")} />
              </Box>
            )}
          </Paper>

          <ServiceConfigModal
            serviceConfigs={sandbox.serviceConfigs as ServiceConfig[]}
            serviceConfiguration={data?.GetServiceConfiguration!}
            onSubmit={addOrReplaceServiceConfig}
            onDelete={deleteServiceConfig}
          />

          <Dialog open={deleteConfirmDialogOpen} maxWidth="xs">
            <DialogTitle>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <>{t("labels__settings_service_config_delete_service_config_confirmation_title")}</>
                <IconButton onClick={() => setDeleteConfirmDialogOpen(false)} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Paragraph>
                <span>
                  {t("labels__settings_service_config_delete_service_config_confirmation_msg1", {
                    name:
                      serviceConfigToDelete?.service?.details?.displayName ||
                      serviceConfigToDelete?.service?.serviceId ||
                      ""
                  })}
                </span>
                <span style={{ color: theme.palette.error.main }}>
                  {t("labels__settings_service_config_delete_service_config_confirmation_msg2")}
                </span>
              </Paragraph>
            </DialogContent>
            <DialogActions>
              <Box sx={{ width: "100%", px: theme.spacing(2), pb: theme.spacing(2) }}>
                <Button
                  variant="primary"
                  color="error"
                  fullWidth
                  onClick={() => {
                    deleteServiceConfig(serviceConfigToDelete!.id);
                    setDeleteConfirmDialogOpen(false);
                  }}
                >
                  {t(
                    "labels__settings_service_config_delete_service_config_confirmation_button_delete"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  fullWidth
                  sx={{ mt: theme.spacing(2) }}
                  onClick={() => setDeleteConfirmDialogOpen(false)}
                >
                  {t("buttons__cancel")}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          <DrawerDetails
            isOpen={!!selectedObject}
            onClose={() => setSelectedObject(undefined)}
            drawerId={"service-config-drawer"}
          >
            <Heading3 sx={{ m: 2, mb: 0 }}>{selectedObject?.title}</Heading3>
            <Paper sx={{ m: 2, p: 1, overflowY: "scroll" }} elevation={0}>
              <pre style={{ fontSize: "0.8rem", lineHeight: "1rem" }}>
                {JSON.stringify(selectedObject?.object, null, 2)}
              </pre>
            </Paper>
          </DrawerDetails>
        </>
      )}
    </>
  );
};

export default ServiceConfigurationOverview;
